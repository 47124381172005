<template>
  <div>
    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col cols="4" class="p-0">
          <PageTitle v-bind:title="'Lemmas'" />
        </b-col>
        <b-col cols="8" class="text-right" v-if="!config.editable">
          <DictionarySelectPI class="mb-3" />
        </b-col>
      </b-row>
      <b-row class="mt-4" v-if="$store.getters.checkRole('legam.dict.article.add')">
        <b-col>
          <b-row>
            <b-col>
              <b-badge href="#" variant="info" class="mr-1" v-for="lemma in selectedLemmaWithoutArticles" :key="lemma.id" @click="removeSelectedLemma(lemma)">
                {{ lemma.lemmaForm }}
                <b-icon icon="trash" aria-hidden="true" class="mr-1"></b-icon>
              </b-badge>
              <br>
              <b-button v-if="selectedLemmaWithoutArticles.length && config.editable" size="sm" variant="primary" class="mt-2" v-b-modal.modal-add-lemmas-to-article>
                <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
                Add lemmas to article
              </b-button>
              <b-modal v-if="config.editable" id="modal-add-lemmas-to-article" title="Add lemma to article" size="md">
                <b-badge href="#" variant="info" class="mr-1" v-for="lemma in selectedLemmaWithoutArticles" :key="'a' + lemma.id" @click="removeSelectedLemma(lemma)">
                  {{ lemma.lemmaForm }}
                  <b-icon icon="trash" aria-hidden="true" class="mr-1"></b-icon>
                </b-badge>
                <ArticleAutocomplete v-if="$store.getters.checkRole('legam.dict.article.modify')" class="mt-4" :allowNew="false" @selectArticle="addLemmasToArticle" />
                <div class="ml-3 mt-2">
                  Dictionary<br>
                  <DictionarySelect @select="setDictionary" :multiple="false" />
                  <br>
                  <span v-for="lemma in selectedLemmaWithoutArticles" :key="lemma.id">
                    <a @click="newArticleFromLemma($event, lemma.id)" href="#">
                      <small>Create new article for</small> <b>{{ lemma.lemmaForm }}</b>
                    </a>
                    <br>
                  </span>
                </div>
              </b-modal>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <div v-if="$store.state.user.settings.isDictionaryFiltered && config.editable" class="mb-3 text-orange">
            <b-icon icon="exclamation-circle-fill" aria-hidden="true"></b-icon>
            Entries in this table are filtered by personal preference for dictionary set in user settings
            ({{ $store.getters.getDictionariesByIds($store.state.user.settings.dictionaries).map(dictionary => dictionary.acronym).join(', ') }})
          </div>
          <b-button v-if="config.editable" variant="primary" size="sm" class="float-right" :disabled="!$store.getters.checkRole('legam.dict.lemma.add')"  v-b-modal.modal-new-lemma>
            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
            Add new lemma
          </b-button>

          <b-modal v-if="config.editable" id="modal-new-lemma" title="New lemma" ok-title="Save" @ok="saveLemma" size="lg">
            <LemmaNewEdit :lemma="null" />
          </b-modal>
          <LegamDataTables
            v-if="config.editable"
            v-bind="$data"
            ref="DataTablesComponent"
            @selectChanged="selectChanged"
            :additionalData="tableAdditionalData"
            :key="key"
          />
          <LegamDataTablesClientSide
            v-else
            v-bind="$data"
            ref="PITable"
            @selectChanged="selectChanged"
            :additionalDataFilter="tableAdditionalDataFilter"
            :key="key"
          />
        </b-col>
      </b-row>
    </b-container>

    <div class="merge-float" v-if="selected.length && config.editable">
      <b-badge href="#" variant="info" class="mr-1" v-for="lemma in selected" :key="lemma.id" @click="removeSelectedLemma(lemma)">
        <span v-html="lemma.lemmaForm"></span>
        <span v-if="lemma.articleId"> (<span v-html="lemma.article.mainLemma.lemmaForm"></span>)</span>
        <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
      </b-badge>
      <span class="btn-legam-xxs btn-legam-xs btn-legam-blue" v-if="selected.length > 1" v-b-modal.modal-merge-lemmas>
        <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
        Merge lemmas
      </span>
      <span class="btn-legam-xxs btn-legam-xs" @click="selected = []">
        <b-icon icon="x" aria-hidden="true"></b-icon>
      </span>
      <b-modal id="modal-merge-lemmas" title="Merge lemmas" ok-title="Merge" @ok="mergeLemmas" size="md">
        <b-badge href="#" variant="info" class="mr-1" v-for="lemma in selected" :key="'a' + lemma.id" @click="removeSelectedLemma(lemma)">
          <span v-html="lemma.lemmaForm"></span>
          <span v-if="lemma.articleId"> (<span v-html="lemma.article.mainLemma.lemmaForm"></span>)</span>
          <b-icon icon="trash" aria-hidden="true" class="ml-1"></b-icon>
        </b-badge>
        <b-form-group
          id="main-lemma-grp"
          label="Main lemma"
          label-for="main-lemma"
          class="mt-3"
          description="This lemma will keep all it's data (other lemmas will move their occurrences to this lemma and then disappear)"
        >
          <b-form-select
            v-model="mainLemma"
            :options="selected.map(lemma => { return {
              'text': lemma.lemmaForm + (lemma.articleId ? ' (' + lemma.article.mainLemma.lemmaForm + ')' : ''),
              'value': lemma.id}
            })"
          ></b-form-select>
        </b-form-group>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  * >>> .tdAction {
    width: 100px;
  }
  .merge-float {
    position: fixed;
    bottom: 15px;
    left: 20px;
    background-color: #bebebe9a;
    padding: 3px 8px 5px;
    border-radius: 3px;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import tdColSafeHTML from '@/components/tdColSafeHTML.vue'
  import tdColAction from '@/components/tdColAction.vue'
  import PageTitle from '@/components/PageTitle.vue'
  import DictionarySelectPI from '@/components/Dictionary/DictionarySelectPI.vue'
  // import tdColUUID from '@/components/tdColUUID.vue'
  import LegamDataTables from '@/components/LegamDataTables.vue'
  import LegamDataTablesClientSide from '@/components/LegamDataTablesClientSide.vue'
  import LemmaNewEdit from '@/components/Lemma/NewEdit.vue'
  import ArticleAutocomplete from '@/components/ArticleAutocomplete.vue'
  import router from '@/router'
  import HelpStore from '@/store/helpstore.js'
  import DictionarySelect from '@/components/DictionarySelect.vue'
  import config from '@/config.js'

  // function onlyUnique(value, index, self) {
  //   return self.indexOf(value) === index
  // }

  export default {
    name: 'LemmaList',
    data() {
      return {
        columns: [
          { title: this.$t('Article'), field: 'article', searchField: 'mainLemma', sortable: true, tdComp: tdColAction, searchable: true, transformData: function(row){
              let retVal = []
              if (row.article != null && row.article.mainLemma != null){
                let mailLemmaForm = config.editable ? row.article.mainLemma.lemmaForm : row.article.mainLemma
                let lemmaId = config.editable ? row.id : row.lemmaForm.id
                let articleId = config.editable ? row.articleId : row.article.articleId
                retVal.push({
                  text: mailLemmaForm,
                  icon: 'book',
                  id: row.id,
                  to: {name: 'Article', params: {id: articleId}, query: {lemmaId: lemmaId}}
                })
              }
              return retVal
            }
          },
          { title: this.$t('Lang'), field: 'languageCode', sortable: true, searchable: true },
          { title: this.$t('POS'), field: 'mainLemmaPartofspeeches', sortable: true, searchable: true },
          { title: this.$t('Etymon'), field: 'etymon', searchField: 'form', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
              let retVal = []
              if (row.etymon && row.etymon.id){
                retVal.push({
                  text: row.etymon.lemmaForm,
                  icon: 'book',
                  id: row.id,
                  to: {name: 'Lemma', params: {id: row.etymon.id}}
                })
              }
              return retVal
          } },
          { title: this.$t('Lang'), field: 'etymonLang', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
              let retVal = []
              if (row.etymon || row.etymonLang){
                retVal.push({
                  text: config.editable ? row.etymon.languageCode : row.etymonLang,
                })
              }
              return retVal
          } },
          { title: this.$t('Lemma'), field: 'lemmaForm', searchField: 'form', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
              let retVal = []
              let prefix = row.isReconstructed ? '*' : ''
              let lemmaId = config.editable ? row.id : row.lemmaForm.id
              let lemmaForm = config.editable ? row.lemmaForm : row.lemmaForm.form
              retVal.push({
                text: `${prefix}${lemmaForm}`,
                icon: 'book',
                id: lemmaId,
                to: {name: 'Lemma', params: {id: lemmaId}}
              })
              return retVal
            } },
          { title: this.$t('Scriptae'), field: 'scriptas', sortable: true, searchable: true },
          { title: this.$t('POS'), field: 'partofspeeches', sortable: true, searchable: true },
          { title: this.$t('CategoriesConcept'), field: 'categories', sortable: true, searchable: true },
          { title: this.$t('Dictionary'), field: 'dictionaryAcronym', sortable: true, tdComp: tdColSafeHTML, searchable: true },

          // article [main lemma form] (hyperlinked, takes you to the article)
          // language [main lemma language]
          // POS [main lemma POS]
          // etymon [main lemma etymon]
          // lemma [lemma form] (hyperlinked, takes you to the lemma)
          // scripta [lemma scripta]
          // POS [lemma POS]
          // category-concept [lemma category-concept]*
          // dictionary [main lemma-article dictionary]
          // forms [spellings of lemma] (this column is mostly for filtering, -> we need to discuss how the forms are actually displayed (a lemma might comprise dozens of forms, I don't know if it's beneficial to display them all - and how))

          // { title: 'ID', field: 'id', sortable: true, tdComp: tdColUUID, searchable: true },




          // { title: 'Public comment', field: 'publicComment', sortable: true, tdComp: tdColSafeHTML, searchable: true },
          // { title: 'Categories', field: 'categories.code', sortable: true, searchable: true,
          //   transformData: row => row.categories.map(category => category.fullCode).filter(onlyUnique).join(', ')
          // },
          // { title: 'Concept', field: 'categories.concept', sortable: true, searchable: true,
          //   transformData: row => row.categories.map(category => category.concept).filter(onlyUnique).join(', ')
          // },

          // { title: 'Details', field: 'action', tdComp: tdColAction, thClass: 'tdAction',
          //   transformData: function(row){
          //     let retVal = [
          //       // {text: 'Edit', icon: 'pencil-fill', id: row.id, to: {name: 'LemmaEdit', id: row.id}},
          //       {text: null, icon: 'list-ul', id: row.id, to: {name: 'Lemma', params: {id: row.id}}}
          //     ]
          //     return retVal
          //   }
          // }
        ],
        columnsPI: [
        { title: 'Article', field: 'article', searchField: 'mainLemma', sortable: true, tdComp: tdColAction, searchable: true, transformData: function(row){
              let retVal = []
              if (row.article != null && row.article.mainLemma != null){
                let mailLemmaForm = config.editable ? row.article.mainLemma.lemmaForm : row.article.mainLemma
                let lemmaId = config.editable ? row.id : row.lemmaForm.id
                let articleId = config.editable ? row.articleId : row.article.articleId
                retVal.push({
                  text: mailLemmaForm,
                  icon: 'book',
                  id: row.id,
                  to: {name: 'Article', params: {id: articleId}, query: {lemmaId: lemmaId}}
                })
              }
              return retVal
            }
          },
          { title: 'Lang.', field: 'languageCode', sortable: true, searchable: true },
          { title: 'POS', field: 'mainLemmaPartofspeeches', sortable: true, searchable: true },
          { title: 'Etymon', field: 'etymon', searchField: 'lemmaForm', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
              let retVal = []
              if (row.etymon && row.etymon.id){
                retVal.push({
                  text: row.etymon.lemmaForm,
                  icon: 'book',
                  id: row.id,
                  to: {name: 'Lemma', params: {id: row.etymon.id}}
                })
              }
              return retVal
          } },
          { title: 'Lang.', field: 'etymonLang', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
              let retVal = []
              if (row.etymon || row.etymonLang){
                retVal.push({
                  text: config.editable ? row.etymon.languageCode : row.etymonLang,
                })
              }
              return retVal
          } },
          { title: 'Lemma', field: 'lemmaForm', searchField: 'form', sortable: true, searchable: true, tdComp: tdColAction, transformData: function(row){
              let retVal = []
              let prefix = row.isReconstructed ? '*' : ''
              let lemmaId = config.editable ? row.id : row.lemmaForm.id
              let lemmaForm = config.editable ? row.lemmaForm : row.lemmaForm.form
              retVal.push({
                text: `${prefix}${lemmaForm}`,
                icon: 'book',
                id: lemmaId,
                to: {name: 'Lemma', params: {id: lemmaId}}
              })
              return retVal
            }
          },
          { title: 'Scriptae', field: 'scriptas', sortable: true, searchable: true },
          { title: 'POS', field: 'partofspeeches', sortable: true, searchable: true },
          { title: 'Categories-Concept', field: 'categories', sortable: true, searchable: true },
          { title: 'Dictionary', field: 'dictionaryAcronym', sortable: true, tdComp: tdColSafeHTML, searchable: false },
        ],
        url: '/lemmas/datatables',
        selected: [],
        selectedDictionary: null,
        key: 1,
        searchableByColumn: true,
        mainLemma: null,
        config: config,
      }
    },
    components: {
      PageTitle,
      LegamDataTables,
      LegamDataTablesClientSide,
      LemmaNewEdit,
      ArticleAutocomplete,
      DictionarySelect,
      DictionarySelectPI,
    },
    computed: {
      ...mapState({
        userSettings: state => state.user.settings,
        userSettingsPI: state => state.user.settingsPI,
      }),
      tableAdditionalData(){
        return {
          'dictionaries': this.userSettings.dictionaries
        }
      },
      selectedLemmaWithoutArticles(){
        return this.selected.filter(lemma => lemma.articleId == null)
      }
    },
    watch: {
      "userSettings"(){
        this.key++
      },
      userSettingsPI: {
        deep: true,
        handler() {
          if (this.$refs.PITable.table) {
            this.$refs.PITable.sortPageFilter()
          }
        }
      },
    },
    methods: {
      tableAdditionalDataFilter(data) {
        if (this.userSettingsPI.dictionaries.length) {
          data = data.filter(row => this.userSettingsPI.dictionaries.includes(row.dictionaryAcronym))
        }
        return data
      },
      mergeLemmas(){
        this.$store.dispatch('mergeLemmas', {
          mainLemmaId: this.mainLemma,
          lemmaIds: this.selected.map(lemma => lemma.id)
        }).then(() => {
          this.selected = []
          this.mainLemma = null
          this.key += 1
        })
      },
      setDictionary(dictionary){
        this.selectedDictionary = dictionary[0]
      },
      selectChanged(items){
        this.selected = items
        if (items.length){
          this.mainLemma = items[0].id
        }
      },
      removeSelectedLemma(lemma){
        this.selected = this.selected.filter(_lemma => _lemma.id != lemma.id)
      },
      addLemmasToArticle(article){
        this.$store.dispatch('addLemmasToArticle', {
          articleId: article.id,
          lemmaIds: this.selectedLemmaWithoutArticles.map(lemma => lemma.id),
        }).then((response) => {
          this.$bvModal.hide('modal-add-lemmas-to-article')
          router.push(`/article/${response.data.id}`)
        })
      },
      newArticleFromLemma(event, lemma){
        event.preventDefault()
        if (this.selectedDictionary){
          this.$store.dispatch('createArticle', {
            lemmas: this.selectedLemmaWithoutArticles.map(_lemma => _lemma.id),
            mainLemmaId: lemma.id,
            dictionaryId: this.selectedDictionary.id
          }).then((response) => {
            router.push(`/article/${response.data.id}`)
          })
        }
        else {
          alert('Please, select dictionary')
        }
      },
      saveLemma(){
        // this.$store.dispatch('createLemma', HelpStore.item)
        // this.$refs.DataTablesComponent.reloadTable()
        this.$store.dispatch('createLemma', HelpStore.item).then((response) => {
          router.push(`/lemma/${response.data.id}`)
        })
      }
    }
  }
</script>
