<template>
  <div class="mt-3" id="select-dictionary-label">
    <div class="title">Dictionary</div>
    <b-form-group>
      <b-form-checkbox-group size="sm" v-model="selected" :options="dictionaryOptions" button-variant="secondary"
        buttons></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<style scoped>
#select-dictionary-label {
  margin-top: 11px !important;
}

div.title {
  margin-right: 4px;
}

* >>>.btn-secondary {
  background-color: #e8e8e8 !important;
  color: #000;
  border-color: #b8b8b8 !important;
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DictionarySelectPI',
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    ...mapState({
      dictionaries: state => state.common.dictionaries,
      userSettingsPI: state => state.user.settingsPI,
    }),
    dictionaryOptions() {
      return this.dictionaries.map(dictionary => {
        return {
          'text': dictionary.acronym,
          'value': dictionary.acronym
        }
      })
    },
  },
  mounted() {
    this.selected = this.dictionaries
      .filter(dictionary => this.userSettingsPI.dictionaries.includes(dictionary.acronym))
      .map(dictionary => dictionary.acronym)
  },
  watch: {
    selected() {
      this.$store.dispatch(
        'setUserSettingsPI',
        { property: 'dictionaries', value: this.selected }
      )

      // let userSettings = JSON.parse(localStorage.getItem('userSettingsPI'))
      // userSettings.dictionaries = this.selected
      // localStorage.setItem('userSettingsPI', JSON.stringify(userSettings))
      // let retVal = this.dictionaries
      //   .filter(dictionary => this.selected.includes(dictionary.id))
      //   .map(dictionary => dictionary.acronym)
      // if (retVal.length == 0) {
      //   retVal = this.dictionaries.map(dictionary => dictionary.acronym)
      // }
      // setTimeout(() => {
      //   this.$emit('select', retVal)
      // }, 100)

    },
  },
}
</script>
